import { API_BASE_PATH_PORTAL, getV2ApiUrl } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import {
  addNonprofit,
  setCauses,
  setCBPartner,
  setExternalPartners,
  setLoadingCauses,
  setLoadingCBPartner,
  setLoadingNonprofit,
  setLoadingNonprofits,
  setLoadingPartner,
  setLoadingPartners,
  setLoadingPaymentStructures,
  setLoadingUser,
  setNonprofit,
  setNonprofits,
  setPartner,
  setPartners,
  setPaymentStructures,
  setUser,
} from '../actions'

export const fetchNonprofits = () => {
  return dispatch => {
    dispatch(setLoadingNonprofits({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/admin/nonprofits`)
      .then(res => {
        dispatch(setNonprofits(res.data))
        dispatch(setLoadingNonprofits({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingNonprofits({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

export const fetchNonprofit = id => {
  return dispatch => {
    dispatch(setLoadingNonprofit({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/admin/nonprofit/${id}`)
      .then(res => {
        dispatch(setNonprofit(res.data))
        dispatch(setLoadingNonprofit({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingNonprofit({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

export const fetchCauses = () => {
  return dispatch => {
    dispatch(setLoadingCauses({ loading: true, error: null }))
    return axiosRequest('get', `${API_BASE_PATH_PORTAL}/admin/causes`)
      .then(res => {
        dispatch(setCauses(res.data))
        dispatch(setLoadingCauses({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingCauses({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

// Return type: TPPPartner[]
export const fetchPartners = () => {
  return dispatch => {
    dispatch(setLoadingPartners({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/all`)
      .then(res => {
        dispatch(setPartners(res.data))
        dispatch(setLoadingPartners({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingPartners({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

export const fetchExternalPartners = () => {
  return dispatch => {
    dispatch(setLoadingPartners({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/external`)
      .then(res => {
        dispatch(setExternalPartners(res.data))
        dispatch(setLoadingPartners({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingPartners({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

// Return type: TPPPartner
export const fetchPartnerByChainId = chainId => {
  return dispatch => {
    dispatch(setLoadingPartner({ loading: true, error: null }))
    axiosRequest('get', `${getV2ApiUrl()}/admin/partner/${chainId}`)
      .then(res => {
        dispatch(setPartner(res.data))
        dispatch(setLoadingPartner({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingPartner({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}

export const fetchCentralBackendPartner = id => {
  return async dispatch => {
    dispatch(setLoadingCBPartner({ loading: true, error: null }))

    try {
      const res = await axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/central/${id}`)
      dispatch(setCBPartner(res.data || null))
      dispatch(setLoadingCBPartner({ loading: false, error: null }))
    } catch (error) {
      dispatch(setCBPartner(null))
      dispatch(setLoadingCBPartner({ loading: false, error }))

      if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
        dispatch(setUser({}))
        return
      }
    }
  }
}

export const createUser = ({ user }) => {
  return dispatch => {
    dispatch(setLoadingUser({ loading: true, error: null }))
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/users/create`, { user })
      .then(() => {
        dispatch(setLoadingUser({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingUser({ loading: false, error }))
      })
  }
}

export const createPartner = partner => {
  return dispatch => {
    dispatch(setLoadingPartner({ loading: true, error: null }))
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/partners/create`, { partner })
      .then(() => {
        dispatch(setLoadingPartner({ loading: false, error: null }))
      })
      .catch(error => {
        console.log('ERROR = ', error)
        dispatch(setLoadingPartner({ loading: false, error }))
      })
  }
}

export const createCentralBackendPartner = partner => {
  const form = new FormData()
  for (const key in partner) {
    if (key === 'targetChainNonprofitInfo' || key === 'stores') {
      form.append(key, JSON.stringify(partner[key]))
    }
    if (
      partner[key] !== undefined &&
      partner[key] !== null &&
      partner[key] !== '' &&
      typeof partner[key] !== 'object'
    )
      form.append(key, partner[key])
  }
  if (partner.logoImage) {
    form.append('logoImage', partner.logoImage, partner.logoImage.name)
    form.append(partner.logoImage.name, 'logoImage')
  }
  for (const index in partner.targetChainNonprofitInfo) {
    const nonprofit = partner.targetChainNonprofitInfo[index]
    if (nonprofit.causeDisplayIcon) {
      form.append(
        'chainNonprofit/causeDisplayIcon/',
        nonprofit.causeDisplayIcon,
        nonprofit.causeDisplayIcon.name
      )
      form.append(nonprofit.nonprofitId + nonprofit.causeDisplayIcon.name, 'causeDisplayIcon')
    }
    if (nonprofit.causeSelectedImage) {
      form.append(
        'chainNonprofit/causeSelectedImage/',
        nonprofit.causeSelectedImage,
        nonprofit.causeSelectedImage.name
      )
      form.append(nonprofit.nonprofitId + nonprofit.causeSelectedImage.name, 'causeSelectedImage')
    }
    if (nonprofit.chainImpactImage) {
      form.append('target/', nonprofit.chainImpactImage, nonprofit.chainImpactImage.name)
      form.append(nonprofit.nonprofitId + nonprofit.chainImpactImage.name, 'chainImpactImage')
    }
  }

  return dispatch => {
    dispatch(setLoadingCBPartner({ loading: true, error: null }))
    axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/admin/partner/central`,
      form,
      {},
      'multipart/form-data'
    )
      .then(res => {
        dispatch(setLoadingCBPartner({ loading: false, error: null, success: res.data }))
      })
      .catch(error => {
        dispatch(setLoadingCBPartner({ loading: false, error }))
      })
  }
}

export const createNonprofit = nonprofit => {
  const form = new FormData()
  for (const key in nonprofit) {
    if (
      nonprofit[key] !== undefined &&
      nonprofit[key] !== null &&
      nonprofit[key] !== '' &&
      typeof nonprofit[key] !== 'object'
    )
      form.append(key, nonprofit[key])
  }
  if (nonprofit.personalPhoto && nonprofit.personalPhoto !== '') {
    form.append('files', nonprofit.personalPhoto, nonprofit.personalPhoto.name)
    form.append(nonprofit.personalPhoto.name, 'personalPhoto')
  }

  if (nonprofit.communityPhoto && nonprofit.communityPhoto !== '') {
    form.append('files', nonprofit.communityPhoto, nonprofit.communityPhoto.name)
    form.append(nonprofit.communityPhoto.name, 'communityPhoto')
  }

  return dispatch => {
    dispatch(setLoadingNonprofits({ loading: true, error: null, success: null }))
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/admin/nonprofit`, form, {}, 'multipart/form-data')
      .then(res => {
        dispatch(addNonprofit(res.data))
        dispatch(setLoadingNonprofits({ loading: false, error: null, success: res.data }))
      })
      .catch(error => {
        dispatch(setLoadingNonprofits({ loading: false, error, success: null }))
      })
  }
}

export const updateNonprofit = nonprofit => {
  const form = new FormData()
  for (const key in nonprofit) {
    if (
      nonprofit[key] !== undefined &&
      nonprofit[key] !== null &&
      nonprofit[key] !== '' &&
      typeof nonprofit[key] !== 'object'
    ) {
      form.append(key, nonprofit[key])
    }

    if (nonprofit[key] === undefined || nonprofit[key] === null || nonprofit[key] === '') {
      form.append(key, 'null')
    }
  }

  if (nonprofit.personalPhoto && nonprofit.personalPhoto !== '') {
    form.append('files', nonprofit.personalPhoto, nonprofit.personalPhoto.name)
    form.append(nonprofit.personalPhoto.name, 'personalPhoto')
  }

  if (nonprofit.communityPhoto && nonprofit.communityPhoto !== '') {
    form.append('files', nonprofit.communityPhoto, nonprofit.communityPhoto.name)
    form.append(nonprofit.communityPhoto.name, 'communityPhoto')
  }

  return dispatch => {
    dispatch(setLoadingNonprofit({ loading: true, error: null, success: null }))
    axiosRequest('put', `${API_BASE_PATH_PORTAL}/admin/nonprofit`, form, {}, 'multipart/form-data')
      .then(res => {
        dispatch(setNonprofit(res.data))
        dispatch(setLoadingNonprofit({ loading: false, error: null, success: res.data }))
      })
      .catch(error => {
        dispatch(setLoadingNonprofit({ loading: false, error, success: null }))
      })
  }
}

export const fetchPaymentStructures = () => {
  return dispatch => {
    dispatch(setLoadingPaymentStructures({ loading: true, error: null }))
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/payment-structures/all`)
      .then(res => {
        dispatch(setPaymentStructures(res.data))
        dispatch(setLoadingPaymentStructures({ loading: false, error: null }))
      })
      .catch(error => {
        dispatch(setLoadingPaymentStructures({ loading: false, error }))
        if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
          dispatch(setUser({}))
          return
        }
      })
  }
}
